import { Box, HStack, Image } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import {
  OpaliteTextHighlighted,
  OpaliteTextStandard,
  OpaliteTextSubTitle,
} from "../shared/opalite_text";
import TempProfilePhoto from "../../images/temp_profile_picture.svg";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";

interface CreatorCardProps {}

const CreatorCard: React.FC<CreatorCardProps> = (props) => {
  return (
    <Box
      padding="1rem"
      width="100%"
      borderRadius="5px"
      borderColor={COLORS_PRIMARY.FONT}
      borderWidth="2px"
    >
      <HStack justifyContent="space-between" marginBottom=".5rem">
        <OpaliteTextStandard marginBottom=".5rem">Creator</OpaliteTextStandard>
        <HStack>
          <OpaliteTextHighlighted>45 views</OpaliteTextHighlighted>
          <OpaliteTextHighlighted>21 likes</OpaliteTextHighlighted>
        </HStack>
      </HStack>
      <HStack marginBottom="1rem">
        <Image src={TempProfilePhoto} />{" "}
        <OpaliteTextSubTitle fontSize="1rem">@Real_JohnDoe</OpaliteTextSubTitle>
      </HStack>
      <hr></hr>
      <OpaliteTextStandard
        fontSize="1.5rem"
        fontWeight="bold"
        marginBlock=".5rem"
      >
        Saturday Coffee #32
      </OpaliteTextStandard>
    </Box>
  );
};

export default CreatorCard;
