import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import AllNFT from "../components/all_nft/all_nft";
import Header from "../components/header/header";
import Footer from "../components/shared/footer";

function AllNFTPage() {
  return (
    <ChakraProvider>
      <Header />
      <AllNFT />
      <Footer />
    </ChakraProvider>
  );
}

export default AllNFTPage;
