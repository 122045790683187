import { Box, Flex, HStack, Image, Progress, VStack } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import {
  OpaliteTextStandard,
  OpaliteTextSubTitle,
} from "../shared/opalite_text";
import PriceIconSVG from "../../images/price_icon.svg";
import CollectionStackIcon from "../../images/pngs/collection_stack_icon.png";

interface CollectionDisplayBoxProps {
  image: string;
  onOpen: Function;
  onClick: Function;
  onClose: Function;
  priceSolana: number;
  priceUSDollar: number;
  collectionTitle: string;
  influencer: string;
  collectionNumber: string;
  contentCount: string;
  collectionId: string;
  isOpen: boolean;
}

const CollectionDisplayBox: React.FC<CollectionDisplayBoxProps> = (props) => {
  return (
    <Box>
      <Box
        onClick={() => props.onClick(props.collectionId)}
        width="10rem"
        height="10rem"
        backgroundColor={"#323232"}
        justifySelf="center"
        zIndex="0"
        backgroundImage={props.image}
        backgroundSize="100% 100%"
        display="flex"
        justifyContent="right"
        alignItems="baseline"
      >
        <Box
          width="3rem"
          height="3rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundImage={CollectionStackIcon}
        >
          <OpaliteTextStandard color={COLORS_PRIMARY.BACKGROUND}>
            {props.contentCount}
          </OpaliteTextStandard>
        </Box>
      </Box>
      <Box
        padding=".5rem"
        paddingBottom=".1rem"
        marginTop="-2rem"
        backgroundColor={COLORS_PRIMARY.NFT_DISPLAY_DESCRIPTION_SECTION}
      >
        <HStack justifyContent="space-between">
          <OpaliteTextSubTitle fontSize=".75rem" fontWeight="bold">
            {props.collectionTitle}
          </OpaliteTextSubTitle>
        </HStack>
        <HStack justifyContent="space-between">
          <OpaliteTextStandard fontSize=".75rem" color={COLORS_PRIMARY.FONT}>
            Floor Price
          </OpaliteTextStandard>
          <HStack>
            <Image src={PriceIconSVG} />{" "}
            <OpaliteTextSubTitle fontSize=".75rem">
              {props.priceSolana}
            </OpaliteTextSubTitle>
          </HStack>
        </HStack>
        <HStack justifyContent="end">
          <OpaliteTextStandard
            color={COLORS_PRIMARY.NFT_DISPLAY_DESCRIPTION_TEXT}
          >
            ${props.priceUSDollar}
          </OpaliteTextStandard>
        </HStack>
      </Box>
    </Box>
  );
};

export default CollectionDisplayBox;
