import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
  Center,
  Stack,
  HStack,
  VStack,
  Switch,
  Flex,
  Image,
} from "@chakra-ui/react";
import { Radio, RadioGroup } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Footer from "../shared/footer";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import ButtonIcon from "../../images/button_icon.svg";
import {
  OpaliteTextStandard,
  OpaliteTextSubTitle,
  OpaliteTextTitle,
} from "../shared/opalite_text";
import TempNFTPhoto from "../../images/temp_nft_picture.svg";
import PriceIconSVG from "../../images/price_icon.svg";
import PriceDetailCard from "./price_detail_card";
import CreatorCard from "./creator_card";

interface NFTDetailDrawerProps {
  isOpen: boolean;
}

const NFTDetailDrawer: React.FC<NFTDetailDrawerProps> = (props) => {
  useEffect(() => {
    const drawer = document.getElementById("drawer");
    if (drawer) {
      drawer.scroll(0, 9999);
    }
  }, [props.isOpen]);
  const renderDrawer = () => {
    return (
      <Box height="100vh" overflow="scroll">
        <Box
          paddingTop="2rem"
          padding="2rem"
          backgroundColor={COLORS_PRIMARY.BACKGROUND}
        >
          <HStack alignItems="center" justifyContent="space-between">
            {" "}
            <OpaliteTextSubTitle marginBottom="1rem" fontWeight="bold">
              Influencer name
            </OpaliteTextSubTitle>
            <DrawerCloseButton
              right="2.5rem"
              onClick={() => props.onClose()}
              color={COLORS_PRIMARY.MENU_FONT_COLOR}
            />
          </HStack>
          <OpaliteTextTitle
            marginBottom="2rem"
            fontSize="2rem"
            marginTop="1rem"
          >
            [NFT Title]
          </OpaliteTextTitle>
          <HStack
            backgroundColor={COLORS_PRIMARY.CONTENT_DISPLAY_BOX_BACKGROUND}
            padding=".5rem"
            justifyContent="space-between"
          >
            <Image width="1rem" src={PriceIconSVG} />{" "}
            <Box
              padding=".5rem"
              borderWidth="2px"
              borderColor={COLORS_PRIMARY.FONT}
              borderRadius="5px"
            >
              <Image src={ButtonIcon} />
            </Box>
          </HStack>
          <Image src={TempNFTPhoto} />
          <OpaliteTextStandard marginBlock="1rem">
            Owned by: [Current Owner]
          </OpaliteTextStandard>
          <PriceDetailCard />
          <OpaliteTextStandard marginTop="1rem">
            More details:
          </OpaliteTextStandard>
          <OpaliteTextStandard
            fontSize=".75rem"
            color={COLORS_PRIMARY.NFT_DISPLAY_DESCRIPTION_TEXT}
            marginBlock="1rem"
          >
            {" "}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam elit
            turpis, feugiat nec blandit in, condimentum eu nisi. Nam vitae velit
            volutpat, rhoncus orci sed, finibus elit. Aenean sit amet velit
            erat. Praesent sed tempus elit, ut accumsan nunc. Curabitur finibus
            ornare massa, quis placerat nibh dictum ut. Cras ut rutrum lacus.
            Nam orci dolor, interdum vehicula metus nec, dapibus convallis
            nulla. Maecenas ac lacinia libero, mattis congue velit. Donec
            iaculis leo eu lorem aliquam, in pharetra justo dignissim. Curabitur
            scelerisque ex risus, imperdiet mollis velit ultricies sed. Nam
            viverra posuere purus. Cras tempus nulla ac sapien fermentum, sit
            amet ullamcorper massa commodo.
          </OpaliteTextStandard>
          <CreatorCard />
        </Box>
        <Footer />
      </Box>
    );
  };
  return (
    <Drawer
      isFullHeight
      placement="bottom"
      onClose={props.onClose}
      isOpen={props.isOpen}
    >
      <DrawerOverlay>
        <DrawerContent
          id="drawer"
          backgroundColor={COLORS_PRIMARY.GRID_GRADIENT}
        >
          {renderDrawer()}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default NFTDetailDrawer;
