import {
  Box,
  Center,
  Flex,
  Grid,
  Select,
  VStack,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";
import {
  OpaliteTextHighlighted,
  OpaliteTextNFTLink,
  OpaliteTextStandard,
  OpaliteTextTitle,
} from "../shared/opalite_text";
import { navigate } from "gatsby";
import CollectionDisplayBox from "./collection_display_box";
import NFTDetailDrawer from "./nft_detail_drawer";
import tempCollectionPicture from "../../images/temp_nft_picture.svg";
import { useState } from "react";
import { useEffect } from "react";
import ExploreCollectionsSquareGradientIcon from "../../images/pngs/explore_collections_square_gradient_icon.png";
import ExploreCollectionsFourSquareGridGradientIcon from "../../images/pngs/explore_collections_four_square_grid_gradient_icon.png";
import { ApiContext } from "../Root";

interface AllNFTProps {}

const AllNFT: React.FC<AllNFTProps> = (props) => {
  const api = useContext(ApiContext);
  const [content, setContent] = useState([]);
  const [collectionId, setCollectionId] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openDrawer = (id: string) => {
    setCollectionId(id);
    console.log(id);
    console.log(isOpen);
    console.log(collectionId);
    onOpen();
  };

  const closeDrawer = (id: string) => {
    setCollectionId(null);
    console.log(id);
    console.log(isOpen);
    console.log(collectionId);
    onClose();
  };

  const allNFTUseEffect = async () => {
    const res = await api.client.getCollections();
    console.log("res", res);
  };

  useEffect(() => {
    allNFTUseEffect();
    setContent([
      {
        collection_id: "810aac4a-288d-4212-94ec-e996ecaf2606",
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
    ]);
  }, []);

  const renderContent = () => {
    const contentList = content.map((collection) => {
      return (
        <CollectionDisplayBox
          collectionId={collection.collection_id}
          onClick={openDrawer}
          isOpen={isOpen}
          onOpen={onOpen}
          image={collection.image}
          collectionTitle={collection.collectionTitle}
          priceSolana={collection.priceSolana}
          priceUSDollar={collection.priceUSDollar}
          influencer={collection.influencer}
          collectionNumber={collection.collectionNumber}
          contentCount={collection.contentCount}
        />
      );
    });
    return contentList;
  };

  return (
    <VStack
      padding="1.5rem"
      paddingTop="4rem"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      height="100%"
    >
      <OpaliteTextTitle>Explore collections.</OpaliteTextTitle>
      <Box marginTop="4rem" width={{ base: "100%", sm: "75%", md: "60%" }}>
        <Box display="flex" flexDirection="row">
          <Select width="65%" marginTop="1rem" color={COLORS_PRIMARY.FONT}>
            <option value="all_items">All items</option>
          </Select>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            alignSelf="end"
            borderColor="white"
            borderWidth="1px"
            borderLeftRadius="5px"
            width="12.5%"
            marginLeft="10%"
            height="2.5rem"
          >
            <Image
              width={{ base: "50%", sm: "30%" }}
              src={ExploreCollectionsSquareGradientIcon}
              onClick={async () => {}}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            alignSelf="end"
            borderColor="white"
            borderWidth="1px"
            borderRightRadius="5px"
            width="12.5%"
            height="2.5rem"
          >
            <Image
              width={{ base: "50%", sm: "30%" }}
              src={ExploreCollectionsFourSquareGridGradientIcon}
            />
          </Box>
        </Box>

        <Select marginTop="1rem" color={COLORS_PRIMARY.FONT}>
          <option value="sort_by">Sort by</option>
        </Select>
      </Box>
      <Grid
        paddingTop="2rem"
        gap={4}
        templateColumns={{
          base: "repeat(2, 4fr)",
          sm: "repeat(3, 4fr)",
          lg: "repeat(4, 4fr)",
        }}
      >
        {renderContent()}
      </Grid>
      <NFTDetailDrawer isOpen={isOpen} onClose={closeDrawer} />
    </VStack>
  );
};

export default AllNFT;
