import { Box, HStack, Image } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import {
  OpaliteTextStandard,
  OpaliteTextSubTitle,
} from "../shared/opalite_text";
import PriceIconSVG from "../../images/price_icon.svg";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";

interface PriceDetailCardProps {}

const PriceDetailCard: React.FC<PriceDetailCardProps> = (props) => {
  return (
    <Box
      padding="1rem"
      width="100%"
      borderRadius="5px"
      borderColor={COLORS_PRIMARY.FONT}
      borderWidth="2px"
    >
      <OpaliteTextStandard marginBottom=".5rem">
        Current Price
      </OpaliteTextStandard>
      <HStack marginBottom=".5rem">
        <Image width="1.5rem" src={PriceIconSVG} />
        <OpaliteTextSubTitle>0.015</OpaliteTextSubTitle>{" "}
        <OpaliteTextStandard
          color={COLORS_PRIMARY.NFT_DISPLAY_DESCRIPTION_TEXT}
          fontSize=".75rem"
        >
          ($39.50)
        </OpaliteTextStandard>
      </HStack>
      <OpaliteButtonStandard
        color={COLORS_PRIMARY.BACKGROUND}
        backgroundColor={COLORS_PRIMARY.HIGHLIGHT_SELECTED}
        marginBottom=".5rem"
        width="100%"
      >
        Buy with Solflare
      </OpaliteButtonStandard>
      <OpaliteTextStandard marginBottom=".5rem">
        Don't have a <b>Phantom Wallet</b>? Creating one only takes a few
        clicks. Start <u>here</u>
      </OpaliteTextStandard>
    </Box>
  );
};

export default PriceDetailCard;
